.App {
  text-align: center;
}

.header-font-white {
  color: #d4e5fa;
}

#navbar {
  position: sticky;
  zIndex: 1003;
  backgroundColor: #476F7C;
  color: #101a24;
}

/*This CSS hide the signUp tab*/
.amplify-tabs {
  display: none;
}

/*This CSS hide the navigation*/
/*div[class*="awsui_navigation-panel_"]{*/
/*  display: none;*/
/*}*/

/* This adjust the SignIn font size */
:root{
--amplify-components-fieldcontrol-small-font-size: 1.5rem;
--amplify-components-button-font-size: 1.5rem;
--amplify-components-fieldcontrol-font-size: 1.5rem;
--amplify-components-tabs-item-font-size: 1.5rem;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 30px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}
